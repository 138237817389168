<template>
  <div class="home-style">
    <div class="bigbox" v-show="actives==1">
      <div class="box1">
        <van-swipe :autoplay="3000" indicator-color="transpareent">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <div @click="swiClick(index)">
              <img :src="image" class="swi-img" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="box2 center-center">
        <div class="box2-b1">
          <van-notice-bar
            left-icon="volume-o"
            color="#323232"
            background="#FFFFFF"
            @click="goAhead('/notice')"
          >
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item,index) in noticeList" :key="index">{{ language==1?item.title:item.Etitle }}</van-swipe-item>
          </van-swipe>
          </van-notice-bar>
        </div>
      </div>
      <div class="case1 center-center">
        <div class="b1 between-center mr9" @click="goAhead('/leaderboard')">
          <div>{{$t('text.t431')}}</div>
          <img src="@/assets/img/img72.png" class="imgs" />
        </div>
        <div class="b1 between-center" @click="goAhead('/hashrate')">
          <div>{{$t('text.t535')}}</div>
          <img src="@/assets/img/img72.png" class="imgs" />
        </div>
      </div>
      <div class="case2">
        <div class="box3 case2-t1">{{$t('text.t433')}}</div>
        <div class="case-box">
          <div class="case-list start-center">
            <el-card class="box-card" v-for="(item,index) in collectionList" :key="index">
              <div class="item between-end" @click="goAhead(`/set?id=${item.id}&chainType=${item.chain}`)">
                <div class="item_bgc" :style="{
                backgroundImage: `url(${item.selectedimage})`
              }"></div>
                <div class="left">
                  <img :src="item.collectionimage" class="left-imgs" />
                  <img v-if="item.identification == 1" src="@/assets/img/img85.png" class="left-imgs-pos" />
                  <img v-else-if="item.identification == 2" src="@/assets/img/img86.png" class="left-imgs-pos" />
                </div>
                <div class="right ellipsis">{{item.collectionname}}</div>
                <img :src="item.chain==0?$concatImg('img81'):item.chain==1?$concatImg('img79'):item.chain==2?$concatImg('img80'):item.chain==3?$concatImg('img82'):''" class="t2" />
              </div>
            </el-card>
            <div class="right-kong"></div>
          </div>
        </div>
      </div>
      <div class="case2">
        <div class="box3 case2-t1 between-center">
          <div>{{$t('text.t434')}}</div>
          <div class="add_check" @click="goAhead('/new_more?type=new')">{{ $t('text.t370') }}</div>
        </div>
        <div class="case-box">
          <div class="case-list start-center">
            <el-card class="box-card1" v-for="(item,index) in latestList" :key="index">
              <div class="item1" @click="goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`)">
                <div class="item1_bgc" :style="{
                backgroundImage: `url(${item.info.image})`
              }"></div>
                <div class="item1-flex between-center">
                  <div class="left">
                    <img :src="item.info.image" class="left-imgs" />
                    <img v-if="item.identification == 1" src="@/assets/img/img85.png" class="left-imgs-pos" />
                    <img v-else-if="item.identification == 2" src="@/assets/img/img86.png" class="left-imgs-pos" />
                  </div>
                  <div class="right ellipsis">{{item.title}}</div>
                </div>
                <div class="item1-price end-center">
                  <div class="t1">{{$t('text.t44')}}</div>
                  <img :src="item.chain==0?$concatImg('img81'):item.chain==1?$concatImg('img79'):item.chain==2?$concatImg('img80'):item.chain==3?$concatImg('img82'):''" class="t2" />
                  <div class="t3">{{item.price}}</div>
                </div>
              </div>
            </el-card>
            <div class="right-kong"></div>
          </div>
        </div>
      </div>
      <div class="case2">
        <div class="addtime between-center">
          <div class="box3 case2-t1">{{$t('text.t435')}}</div>
          <div class="atchange center-center">
            <div class="atitem center-center" :class="timeActive==1?'atac':'atno'" @click="changeTimeActive(1)">24h</div>
            <div class="atitem center-center" :class="timeActive==2?'atac':'atno'" @click="changeTimeActive(2)">{{$t('text.t436')}}</div>
          </div>
        </div>
        <div class="case-box">
          <div class="case-list zhangdie">
            <div class="zd-item start-center" v-for="(item,index) in volumeList" :key="index" @click="goAhead(`/set?id=${item.id}&chainType=${item.chain}`)">
              <img :src="item.collectionimage" alt="" class="zd-img">
              <div class="zd-box">
                <div class="t1 ellipsis">{{item.collectionname}}</div>
                <div v-if="timeActive == 1" :class="parseFloat(item.volume_day)>0?'t2':'t3'">{{item.volume_day}}%</div>
                <div v-if="timeActive == 2" :class="parseFloat(item.volume_week)>0?'t2':'t3'">{{item.volume_week}}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="case2">
        <div class="box3 case2-t1">{{$t('text.t437')}}</div>
        <div class="case-box">
          <div class="case-list start-center">
            <div class="fl-item" v-for="item in sortList" :key="item.id" :style="{
              backgroundImage: `url(${$http.imgurl + item.background_image})`
            }" @click="goAhead(`/sort?info=${JSON.stringify(item)}`)">
              {{language==2?item.etitle:item.title}}
            </div>
          </div>
        </div>
      </div>
      <div class="case2 case2-mb0">
        <div class="box3 case2-t1 between-center">
          <div>{{$t('text.t438')}}</div>
          <div class="add_check" @click="goAhead('/new_more?type=hot')">{{ $t('text.t370') }}</div>
        </div>
        <div class="nftlist start-center" v-if="list.length">
          <div class="items-out" v-for="(item,index) in list" :key="index">
            <div class="items" @click="goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`)">
              <img :src="item.info.image" class="i1" />
              <div class="b1 between-center">
                <div class="t1 ellipsis">{{item.title}}</div>
              </div>
              <div class="b2 start-center">
                <img v-if="item.identification == 1" src="@/assets/img/img85.png" class="left-imgs-pos" />
                <img v-else-if="item.identification == 2" src="@/assets/img/img86.png" class="left-imgs-pos" />
                <div>#{{item.tokenid}}</div>
              </div>
              <div class="b3 start-center">
                <img :src="chain_list.find(ele=>ele.chain_type==item.chain).chain_logo" class="b3i1" />
                <div class="b3t1">{{item.price}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="kong" v-else>{{ $t('text.t427') }}</div>
        <div class="fpage center-center" v-if="last_page != 0">
          <van-icon name="arrow-left" size="16" color="#E1E1E1" v-if="current_page == 1" />
          <van-icon name="arrow-left" size="16" color="#323232" v-else @click="reducepage" />
          <div class="fitem">{{$t('text.t375')}}{{current_page}}{{$t('text.t439')}}，{{$t('text.t440')}}{{last_page}}{{$t('text.t439')}}</div>
          <van-icon name="arrow" size="16" color="#E1E1E1" v-if="current_page >= last_page" />
          <van-icon name="arrow" size="16" color="#323232" v-else @click="addpage" />
        </div> -->
      </div>
    </div>
    <div class="bigbox" v-show="actives==2">
      <div class="box1">
        <van-swipe :autoplay="3000" indicator-color="transpareent">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <div @click="swiClick(index)">
              <img :src="image" class="swi-img" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="mintsbox">
        <div class="box3">{{$t('text.t441')}}</div>
        <div class="mslist">
          <div class="msitem">
            <el-card class="box-card">
              <div class="row1 between-center">
                <img src="@/assets/img/img84.png" class="row1i1" />
                <div class="row1t1">{{$t('text.t6')}}</div>
                <!-- <div class="row1btn center-center" @click="goAhead('/mints')">{{$t('text.t474')}}</div> -->
                <div class="row1btn center-center">{{$t('text.t234')}}</div>
              </div>
              <div class="row2">
                <div class="msb1">
                  {{$t('text.t442')}}
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div class="box3">{{$t('text.t443')}}</div>
        <div class="mslist">
          <div class="msitem" v-for="item in hotActiveList" :key="item.id">
            <el-card class="box-card">
              <div class="items" :style="{
              backgroundImage: `url(${$http.imgurl + item.longimage})`
            }" @click="gogogo(item)">
                <div class="msb1 start-center">
                  <img :src="$http.imgurl + item.smallimage" class="i1" />
                  <div class="t1">{{language==1?item.title:item.etitle}}</div>
                </div>
                <div class="msb2 between-center">
                  <div></div>
                  <div class="start-center">
                    <img :src="chain_list.find(ele=>ele.chain_type==item.chain).chain_logo" class="i1" />
                    <div class="msb2t2">{{chain_list.find(ele=>ele.chain_type==item.chain).name}}</div>
                  </div>
                  <div class="start-center">
                    <div class="msb2t1">{{$t('text.t396')}}：</div>
                    <img :src="$http.imgurl + item.sourceimage" class="i1" />
                    <div class="msb2t2">{{item.source}}</div>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Lazyload } from "vant";
Vue.use(Lazyload);
export default {
  name: "Home",
  components: {},
  data() {
    return {
      images: [],
      collectList:[],
      heatList:[],
      hotList:[],
      questionList:[],
      swiperList:[],
      swiObj:{},
      collectionList:[],
      latestList:[],
      timeActive:1,
      sortList:[],
      volumeList:[],
      list: [],
      loading: false,
      finished: false,
      page: 1,
      current_page:1,
      last_page:0,
      limit: 10,
      noticeList:[],
      hotActiveList:[]
    };
  },
  computed: {
    ...mapState(["device","language","islogin","actives","chain_list"]),
  },
  watch:{
    language(){
      this.getSwiper()
    }
  },
  created(){
    this.$local.save('shop:invite',Object.keys(this.$route.query)[0])
    // this.getCollectList()
    // this.getHeatList()
    // this.gethotList()
    // this.getQuestions()
    this.getSwiper()
    this.getnotice()
    this.getCollectionList()
    this.getLatestList()
    this.getSortList()
    this.getVolumeList()
    this.getHotActiveList()
    this.getlist()
  },
  mounted(){
    this.$bus.$on("reload_newest", () => {
      this.getLatestList()
    });
  },
  methods: {
    reducepage(){
      --this.page;
      this.finished = this.finished ? false : this.finished
      this.getlist()
    },
    addpage(){
      ++this.page;
      this.getlist()
    },
    gogogo(item){
      if(item.href_type == 1){
        //场内
        this.$router.push(item.href)
      } else if(item.href_type == 0){
        //场外
        window.location.href = item.href
      }
    },
    getHotActiveList(){
      this.$http.get('/core/activity',{
        page:1,
        limit:20,
      }).then(res=>{
        this.hotActiveList = res.data.data
      })
    },
    getlist() {
      if(this.loading || this.finished) return
      this.loading = true
      let arg = {
        page: this.page,
        limit: 10,
      };
      this.$http.get("/core/hot", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        this.last_page = last_page
        this.current_page = current_page
        if (current_page >= last_page) this.finished = true;
        this.loading = false;
        this.list = data;
      });
    },
    getVolumeList(){
      this.$http.get('/core/volume',{
        page:1,
        limit:20,
        type:this.timeActive
      }).then(res=>{
        this.volumeList = res.data.data
      })
    },
    getSortList(){
      this.$http.get('/core/classificationList',{
        page:1,
        limit:100,
        language:this.language
      }).then(res=>{
        this.sortList = res.data.data
      })
    },
    changeTimeActive(e){
      this.timeActive = e
      this.getVolumeList()
    },
    getLatestList(){
      this.$http.get('/core/newest',{
        page:1,
        limit:20,
      }).then(res=>{
        this.latestList = res.data.data
      })
    },
    getCollectionList(){
      this.$http.get('/core/recommendations',{
        page:1,
        limit:100,
      }).then(res=>{
        this.collectionList = res.data.data
      })
    },
    swiClick(i){
      this.swiperList[i].type == 0 ? 
      console.log('跳转') : 
      this.swiperList[i].type == 1 ? 
      console.log('不跳转') : ''
    },
    getnotice(){
      this.$http.get('/index/AnnounceList',{
        page:1,
        limit:3,
      }).then(res=>{
        this.noticeList = res.data.data.map(ele=>{
          return {
            title:ele.title,
            Etitle:ele.Etitle
          }
        })
      })
    },
    getSwiper(){
      this.$http.get('/index/swiperList',{
        page:1,
        limit:20,
        language:this.language
      }).then(res=>{
        this.swiperList = res.data.data
        this.images = res.data.data.map(ele=> this.$http.imgurl + ele.image)
      })
    },
    getQuestions(){
      this.$http.get('/index/problemList',{
        page:1,
        limit:5,
      }).then(res=>{
        this.questionList = res.data.data
      })
    },
    getCollectList(){
      this.$http.get('/collections/collectList',{
        page:1,
        limit:10,
      }).then(res=>{
        this.collectList = res.data.data
      })
    },
    getHeatList(){
      this.$http.get('/collections/heatList',{
        page:1,
        limit:this.device == 'h5'?12:6,
      }).then(res=>{
        if(this.device == 'h5'){
          if(res.data.data.length == 0){
            this.heatList = []
          } else if(res.data.data.length <= 6){
            this.heatList = [res.data.data]
          } else{
            let arr1 = res.data.data.filter((ele,index)=>index < 6)
            let arr2 = res.data.data.filter((ele,index)=>index >= 6)
            this.heatList = [arr1,arr2]
          }
        } else{
          if(res.data.data.length == 0){
            this.heatList = []
          } else if(res.data.length <= 3){
            this.heatList = [res.data.data]
          } else{
            let arr1 = res.data.data.filter((ele,index)=>index < 3)
            let arr2 = res.data.data.filter((ele,index)=>index >= 3)
            this.heatList = [arr1,arr2]
          }
        }
      })
    },
    gethotList(){
      this.$http.get('/order/marketList',{
        page:1,
        limit:6,
        title:''
      }).then(res=>{
        this.hotList = res.data.data
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    const whitelist = ["new_more"];
    console.log(whitelist.includes(to.name));
    if (whitelist.includes(to.name)) {
      to.meta.useKeep = false;
    }
    next();
  },
};
</script>

<style lang="less">
.home-style {
  overflow-anchor: none;
  .bigbox {
    padding: 12px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .box1 {
      width: 335px;
      .swi-img {
        width: 335px;
        height: 136px;
        border-radius: 10px;
      }
      .van-swipe__indicator {
        background-color: rgba(0, 0, 0, 0) !important;
      }
      .van-swipe__indicator--active {
        width: 100px;
        border-radius: 50px;
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
    .box2 {
      width: 375px;
      height: 60px;
      background-image: url("~@/assets/img/bg2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .box2-b1 {
        width: 363px;
      }
      .notice-swipe{
        height: 40px;
        line-height: 40px;
      }
    }
    .box3 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 20px;
      .add_check{
        font-size: 14px;
        padding-right: 20px;
        font-weight: normal;
        text-decoration: underline rgba(#6319b7, 0.5);
        color: #6319b7;
      }
    }
    .case1{
      margin-bottom: 30px;
      .b1{
        width: 167px;
        height: 41px;
        background: #F4F4F4;
        border: 1px solid #E1E1E1;
        border-radius: 5px;
        padding: 0 12px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        box-sizing: border-box;
        .imgs{
          width: auto;
          height: 17px;
        }
      }
      .mr9{
        margin-right: 9px;
      }
    }
    .case2{
      width: 100%;
      margin-bottom: 30px;
      .addtime{
        padding-right: 20px;
        .atchange{
          padding: 1px;
          background: #E1E1E1;
          border-radius: 5px;
          margin-bottom: 20px;
          .atitem{
            border-radius: 5px;
            padding: 6px 13px;
            font-size: 12px;
            font-weight: bold;
            color: #090B0D;
          }
          .atac{
            background-color: #fff;
          }
          .acno{
            background: #E1E1E1;
          }
        }
      }
      .case2-t1{
        padding: 0 0 0 20px;
      }
      .case-box{
        overflow: auto;
        width: 100%;
        scroll-snap-type: x mandatory;
        .case-list{
          padding: 0 20px;
          .el-card__body, .el-main{
            padding: 0;
          }
          .box-card{
            flex-shrink: 0;
            width: 150px;
            border-radius: 10px;
            margin-right: 15px;
            .t2{
              width: 12px;
              height: 12px;
            }
          }
          .box-card1{
            flex-shrink: 0;
            width: 193px;
            border-radius: 10px;
            margin-right: 20px;
            scroll-snap-align: center;
          }
          .item1{
            width: 193px;
            padding: 71px 10px 15px;
            box-sizing: border-box;
            position: relative;
            .item1_bgc{
              width: 100%;
              height: 83px;
              position: absolute;
              left: 0;
              top: 0;
              background-size: cover;
              background-repeat: no-repeat;
            }
            .item1-flex{
              .left{
                width: 45px;
                height: 45px;
                border-radius: 6px;
                box-sizing: border-box;
                margin-right: 9px;
                position: relative;
                .left-imgs{
                  width: 100%;
                  height: 100%;
                  border-radius: 10px;
                }
                .left-imgs-pos{
                  position: absolute;
                  right: -4px;
                  bottom: -4px;
                  width: 14px;
                  height: 14px;
                  border-radius: 50%;
                }
              }
              .right{
                flex: 1;
                padding-top: 6px;
                font-size: 12px;
                font-weight: bold;
                color: #323232;
              }
            }
            .item1-price{
              .t1{
                font-size: 12px;
                font-weight: 500;
                color: #909090;
              }
              .t2{
                width: 12px;
                height: 12px;
                margin: 0 5px;
              }
              .t3{
                font-size: 13px;
                font-weight: 500;
                color: #323232;
              }
            }
          }
          .right-kong{
            width: 1px;
            height: 50px;
            flex-shrink: 0;
          }
          .item{
            width: 150px;
            padding: 77px 10px 15px;
            box-sizing: border-box;
            position: relative;
            .item_bgc{
              width: 100%;
              height: 100px;
              position: absolute;
              left: 0;
              top: 0;
              background-size: cover;
              background-repeat: no-repeat;
            }
            .left{
              width: 45px;
              height: 45px;
              box-sizing: border-box;
              margin-right: 9px;
              border-radius: 6px;
              position: relative;
              .left-imgs{
                width: 100%;
                height: 100%;
              }
              .left-imgs-pos{
                position: absolute;
                right: -4px;
                bottom: -4px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
              }
            }
            .right{
              flex: 1;
              font-size: 12px;
              font-weight: bold;
              color: #323232;
            }
          }
          .fl-item{
            flex-shrink: 0;
            width: 102px;
            height: 102px;
            border-radius: 10px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            box-sizing: border-box;
            padding: 15px;
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;
            margin-right: 25px;
          }
        }
        .zhangdie{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          height: 117px;
          .zd-item{
            flex-shrink: 0;
            margin-right: 25px;
            .zd-img{
              width: 45px;
              height: 46px;
              border-radius: 3px;
            }
            .zd-box{
              padding-left: 10px;
              width: 74px;
              .t1{
                font-size: 18px;
                font-weight: bold;
                color: #323232;
                margin-bottom: 9px;
              }
              .t2{
                font-size: 12px;
                font-weight: 500;
                color: #3EB08E;
              }
              .t3{
                font-size: 12px;
                font-weight: 500;
                color: red;
              }
            }
          }
        }
      }
      .nftlist{
        flex-wrap: wrap;
        .items-out{
          padding: 0 18px 25px;
          .items{
            width: 150px;
            .i1{
              width: 100%;
              height: 150px;
              border-radius: 3px;
              margin-bottom: 5px;
            }
            .b1{
              padding: 0 16px 9px 0;
              .t1{
                flex: 1;
                font-size: 12px;
                font-weight: 500;
                color: #909090;
              }
              .b1i1{
                width: 10px;
                height: 10px;
                margin-left: 10px;
              }
            }
            .b2{
              font-size: 16px;
              font-weight: bold;
              color: #323232;
              margin-bottom: 9px;
              .left-imgs-pos{
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 5px;
              }
            }
            .b3{
              .b3i1{
                width: 15px;
                height: 15px;
                margin-right: 4px;
              }
              .b3t1{
                font-size: 12px;
                font-weight: 500;
                color: #323232;
              }
            }
          }
        }
      }
      .fpage{
        padding-bottom: 25px;
        .fitem{
          font-size: 13px;
          font-weight: 500;
          color: #232F47;
          margin: 0 10px;
        }
      }
      .kong{
        color: #969799;
        font-size: 12px;
        padding-bottom: 20px;
        text-align: center;
      }
    }
    .case2-mb0{
      margin-bottom: 0;
    }
    .mintsbox{
      width: 100%;
      box-sizing: border-box;
      padding: 20px 20px 0;
      .mslist{
        .msitem{
          width: 100%;
          margin-bottom: 25px;
          .el-card__body, .el-main{
            padding: 0;
          }
          .box-card{
            width: 100%;
            border-radius: 10px;
          }
          .items{
            background-size: 100% 75px;
            background-repeat: no-repeat;
            padding: 64px 10px 10px;
            .msb1{
              margin-bottom: 5px;
              .i1{
                width: 45px;
                height: 45px;
                margin-right: 5px;
                border-radius: 5px;
              }
              .t1{
                font-size: 15px;
                font-weight: bold;
                color: #323232;
              }
            }
            .msb2{
              .msb2t1{
                font-size: 12px;
                font-weight: 500;
                color: #909090;
              }
              .i1{
                width: 12px;
                height: 12px;
                margin: 0 5px;
              }
              .msb2t2{
                font-size: 13px;
                font-weight: 500;
                color: #323232;
              }
            }
          }
          .row1{
            padding: 10px 16px 15px 10px;
            border-bottom: 1px solid #E1E1E1;
            .row1i1{
              width: 45px;
              height: 45px;
              border-radius: 6px;
              margin-right: 15px;
            }
            .row1t1{
              flex: 1;
              font-size: 15px;
              font-weight: bold;
              color: #323232;
            }
            .row1btn{
              background: #323232;
              border-radius: 25px;
              padding: 7px 14px;
              font-size: 12px;
              font-weight: bold;
              color: #FFFFFF;
              box-shadow: 2px -3px 0 #ccc;
            }
          }
          .row2{
            padding: 15px 12px 10px;
            .msb2{
              .msb2t1{
                font-size: 12px;
                font-weight: 500;
                color: #909090;
              }
              .i1{
                width: 12px;
                height: 12px;
                margin: 0 5px;
              }
              .msb2t2{
                font-size: 13px;
                font-weight: 500;
                color: #323232;
              }
            }
            .msb1{
              font-size: 12px;
              font-weight: 500;
              color: #909090;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
  @media all and (min-width: 1080px) {
    .bigbox {
      padding: 30Px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .box1 {
        width: 1000Px;
        .swi-img {
          width: 1000Px;
          height: 479Px;
          border-radius: 20Px;
        }
      }
      .box2 {
        width: 1064Px;
        height: 62px;
        background-image: url("~@/assets/img/bg2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .box2-b1 {
          width: 1000Px;
        }
      }
      .box3 {
        font-size: 36Px;
        font-weight: bold;
        color: #323232;
      }
    }
  }
}
</style>